import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Alert, Button, Form, Input, Select, AutoComplete } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { cities, patterns } from '../Constants'
import { checkForEmptySpaces } from '../HelperFunctions/General'
import Footer from './Footer'
import NavigationBar from './NavigationBar'
import './PeguPayments-style.css'
import ShareSunPowerParagraph from './ShareSunPowerParagraph'
import SPDParagraph from './SPDParagraph'
import DisclaimerParagraph from './DisclaimerParagraph'
import useDebouncedValue from '../hooks/useDebouncedValue'

const SAVE_REFERAL_FORM = gql`
  mutation createReferral(
    $referralFirstName: String!
    $referralLastName: String!
    $referralEmail: String
    $referralPhone: String!
    $referralCity: String!
    $referralState: String!
    $fName: String!
    $lName: String!
    $email: String!
    $referralAddress1: String
    $referralAddress2: String
    $referralZip: String
    $additionalComments: String
    $energyConsultantName: String
    $refererAddress1: String!
    $refererAddress2: String
    $refererCity: String!
    $refererState: String!
    $refererZip: String!
    $refererPhone: String!
    $campaignName: String!
    $campaignCode: String!
    $energyConsultantId: ID
    $isReferrerLink: Boolean
    $referralLeadSource: String
    $nameOfLeadSource: String
  ) {
    createReferral(
      input: {
        referralFirstName: $referralFirstName
        referralLastName: $referralLastName
        referralEmail: $referralEmail
        referralPhone: $referralPhone
        referralCity: $referralCity
        referralState: $referralState
        fName: $fName
        lName: $lName
        email: $email
        referralAddress1: $referralAddress1
        referralAddress2: $referralAddress2
        referralZip: $referralZip
        additionalComments: $additionalComments
        energyConsultantName: $energyConsultantName
        refererAddress1: $refererAddress1
        refererAddress2: $refererAddress2
        refererCity: $refererCity
        refererState: $refererState
        refererZip: $refererZip
        refererPhone: $refererPhone
        campaignName: $campaignName
        campaignCode: $campaignCode
        energyConsultantId: $energyConsultantId
        isReferrerLink: $isReferrerLink
        referralLeadSource: $referralLeadSource
        nameOfLeadSource: $nameOfLeadSource
      }
    ) {
      id
      referralFirstName
      referralLastName
      referralEmail
      referralPhone
      referralAddress1
      referralAddress2
      referralCity
      referralState
      referralZip
      fName
      lName
      email
      additionalComments
      trackingNo
    }
  }
`

const emailIdCheck = gql`
  query EmailCheck($referralEmail: String) {
    emailCheck(referralEmail: $referralEmail) {
      isFound
      id
    }
  }
`

const GET_ENERGY_CONSULTANT = gql`
  query GetEnergyConsultantByName($name: String!) {
    getEnergyConsultantByName(name: $name) {
      name
      email
      phone
      ecManagerName
      ecManagerEmail
      ecManagerPhone
      qrCodeUrl
      isActive
      isRegisteredEmailSent
      _id
    }
  }
`

const getRefferalDetailsByEmail = gql`
  query GetReferralByEmail($email: String!) {
    getReferralByEmail(email: $email) {
      id
      fName
      lName
      email
      refererAddress1
      refererAddress2
      refererCity
      refererState
      refererZip
      refererPhone
      energyConsultantId {
        name
        _id
      }
    }
  }
`

const AUTOCOMPLETEADDRESS = gql` 
      mutation AutocompleteAddress($address:String!){
        autoCompleteAddress(address: $address) {
          streetLine
          secondary
          city
          state
          zipcode
          entries
        }
      }`

const CampaignPage = (props) => {
  const {
    campaignHeader,
    campaignCode,
    campaignName,
    campaignBody,
    campaignDisclaimer,
    heroImage,
    referralLeadSource,
    isRedirectedFromSpd,
    refemail,
  } = props

  const { Option } = Select

  //hooks
  const [form] = Form.useForm()
  const redirect = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  //state variables
  const [status, setStatus] = useState()
  const [errorRes, setError] = useState()
  const [disable, setDisable] = useState(false)
  const [hidden, setHidden] = useState(true)
  const [EmailConfirmed, setEmailCheck] = useState(false)
  const [refEmail, setReferralEmail] = useState()
  const [hiddenRefSection, setHideReferrerSection] = useState(false)
  const [options, setoptions] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const { debouncedValue, debouncing } = useDebouncedValue(searchTerm)

  //Route and page related constant
  const isSPD = campaignName.includes('SPD')
  const energyconsultant = searchParams.get('name')
  const refEmailFromToken = searchParams.get('refemail') || refemail

  //api for energy consultant token link
  const [getEnergyConsultant, { data, loading, error }] = useLazyQuery(
    GET_ENERGY_CONSULTANT
  )

  //api for referer token link
  const [getReferrerDetails, { data: referrerData }] = useLazyQuery(
    getRefferalDetailsByEmail
  )

  //api create referral
  const [saveReferalForm] = useMutation(SAVE_REFERAL_FORM)

  // autocomplete address
  const [getAutoCompleteAddress] = useMutation(AUTOCOMPLETEADDRESS, {
    variables: { address: debouncedValue },
    onCompleted(data) {
      let res = data.autoCompleteAddress.map((record) => ({
        value: `${record.streetLine},${record.secondary},${record.city},${record.state},${record.zipcode}`,
        label: `${record.streetLine},${record.secondary},${record.city},${record.state},${record.zipcode}`,
      }))
      setoptions(res)
    },
  })

  useEffect(() => {
    if (debouncedValue.length > 2) {
      getAutoCompleteAddress()
    }
    if (debouncedValue.length < 2) {
      setoptions([])
    }
  }, [debouncedValue, getAutoCompleteAddress])

  const onSelect = async (value, option) => {
    const addressParts = value.split(',')
    form.setFieldsValue({
      referralAddress1: addressParts[0],
    })
    form.setFieldsValue({
      referralAddress2: addressParts[1],
    })
    form.setFieldsValue({
      referralCity: addressParts[2],
    })
    form.setFieldsValue({
      referralState: addressParts[3],
    })
    form.setFieldsValue({
      referralZip: addressParts[4],
    })
  }

  const onRefrerSelect = async (value, option) => {
    const addressParts = value.split(',')
    form.setFieldsValue({
      refererAddress1: addressParts[0],
    })
    form.setFieldsValue({
      refererAddress2: addressParts[1],
    })
    form.setFieldsValue({
      refererCity: addressParts[2],
    })
    form.setFieldsValue({
      refererState: addressParts[3],
    })
    form.setFieldsValue({
      refererZip: addressParts[4],
    })
  }

  useEffect(() => {
    localStorage.setItem('initialUrl', location.pathname)
  }, [location])

  const onCheck = () => {
    form.validateFields().then((res) => {
      if (res) {
        setDisable(true)
        setHidden(false)
      }
    })
  }

  const handleGetEnergyConsultant = () => {
    //get energy consultant data if link has name.
    if (!data && energyconsultant && !error) {
      getEnergyConsultant({
        variables: {
          name: energyconsultant,
        },
      })
    }

    //auto fill energy consultant if present.
    if (data && !loading) {
      form.setFieldsValue({
        energyConsultantName: data.getEnergyConsultantByName.name,
      })
    }
  }

  // set EC Name to "SPD" if redirected from SPD page
  if (isRedirectedFromSpd) {
    form.setFieldsValue({
      energyConsultantName: 'SPD',
    })
  }

  const handleGetReferrerDetailsByName = () => {
    setHideReferrerSection(true)
    //get referrer data if link has refName
    if (!referrerData && refEmailFromToken) {
      getReferrerDetails({
        variables: {
          email: refEmailFromToken,
        },
      })
    }
  }

  useEffect(() => {
    if (isSPD && energyconsultant) {
      handleGetEnergyConsultant()
    }

    if (refEmailFromToken) {
      handleGetReferrerDetailsByName()
    }
  }, [isSPD, energyconsultant, refEmailFromToken, data])

  //submit form
  const submitHandler = async () => {
    const {
      referralFirstName,
      referralLastName,
      referralEmail,
      referralPhone,
      referralAddress1,
      referralAddress2,
      referralCity,
      referralState,
      referralZip,
      fName,
      lName,
      email,
      additionalComments,
      energyConsultantName,
      refererAddress1,
      refererAddress2,
      refererCity,
      refererState,
      refererZip,
      referrerPhone,
    } = form.getFieldValue()
    try {
      let formData = {
        referralFirstName,
        referralLastName,
        referralEmail: referralEmail || ' ',
        referralPhone,
        referralAddress1,
        referralAddress2,
        referralCity,
        referralState,
        referralZip,
        fName,
        lName,
        email,
        additionalComments,
        energyConsultantName,
        refererAddress1,
        refererAddress2,
        refererCity,
        refererState,
        refererZip,
        refererPhone: referrerPhone,
        campaignName,
        campaignCode,
        referralLeadSource:
          isSPD && energyconsultant
            ? 'EC Individual Link'
            : isSPD && refEmailFromToken
            ? 'Referrer Individual Link'
            : isSPD
            ? 'SPD Referral landing page'
            : 'Share SunPower Referral Landing page',
      }

      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          formData[key] = ''
        }
      })

      //adding energy consultant id if spd/?name
      if (isSPD && data) {
        formData.energyConsultantId = data.getEnergyConsultantByName._id
        formData.nameOfLeadSource = data.getEnergyConsultantByName.name
      }

      //adding referrer data if refEmail
      if (referrerData) {
        formData = {
          ...formData,
          fName: referrerData.getReferralByEmail.fName,
          lName: referrerData.getReferralByEmail.lName,
          email: referrerData.getReferralByEmail.email,
          nameOfLeadSource:
            referrerData.getReferralByEmail.fName +
            ' ' +
            referrerData.getReferralByEmail.lName,
          // Send 'SPD' as EC Name
          // energyConsultantName:
          //   referrerData.getReferralByEmail?.energyConsultantId?.name,
          refererAddress1: referrerData.getReferralByEmail.refererAddress1,
          refererAddress2: referrerData.getReferralByEmail.refererAddress2,
          refererCity: referrerData.getReferralByEmail.refererCity,
          refererState: referrerData.getReferralByEmail.refererState,
          refererZip: referrerData.getReferralByEmail.refererZip,
          refererPhone: referrerData.getReferralByEmail.refererPhone,
          // energyConsultantId:
          //   referrerData.getReferralByEmail?.energyConsultantId?._id,
          isReferrerLink: true,
        }
      }

      const res = await saveReferalForm({
        variables: formData,
      })

      if (res) {
        setStatus('Data Submitted Successfully!')
        window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' })
        redirect('/confirmation', {
          state: {
            message: 'Your referral request has been successfully submitted!',
          },
        })
      }
    } catch (err) {
      window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' })
      setError(err.message)
    }
  }

  const checkingEmail = () => {
    const referralEmailVal = form.getFieldValue('referralEmail')
    setReferralEmail(referralEmailVal)
  }

  const [checkDuplicateEmail] = useLazyQuery(emailIdCheck)

  useEffect(() => {
    const validationSerialNum = setTimeout(async () => {
      if (refEmail != null || refEmail != undefined) {
        const res = await checkDuplicateEmail({
          variables: {
            referralEmail: refEmail,
          },
        })
        let validate = await res.data.emailCheck.isFound
        setEmailCheck(validate)
        form.validateFields(['referralEmail'])
      }
    }, 1000)
    return () => clearTimeout(validationSerialNum)
  }, [refEmail, EmailConfirmed])

  function debounce(func, delay) {
    let timerId
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId)
      }
      timerId = setTimeout(() => {
        func.apply(this, args)
        timerId = null
      }, delay)
    }
  }

  const debouncedCheckingEmail = debounce(checkingEmail, 1000)

  return (
    <>
      <Container fluid className='px-0 overflow-hidden'>
        <NavigationBar />
        <Container>
          <Row className='text-white bg-SunPowerBlue'>
            <Col lg={6} className='px-0'>
              <Image
                fluid
                src={
                  heroImage === 'shareSunPower'
                    ? '/images/Share_SunPower_Customer_Referrals_web.png'
                    : '/images/Refer_SunPower_SPD_web.png'
                }
                className='ms-0'
              />
            </Col>
            <Col lg={6} className='p-5 align-self-center'>
              <h2 className='text-white display-4'>{campaignHeader}</h2>
              <h4 className='text-white fs-5'>{campaignBody}</h4>
            </Col>
          </Row>
        </Container>
        <Container className='pt-2'>
          <Row className='pt-2'>
            <Col>
              <>
                <h6 className='text-center'>
                  SunPower appreciates your referral. Who would you like us to
                  reach out to?
                </h6>
                <Row>
                  <Col className='mt-3 mb-3'>
                    {status ? (
                      <Alert message={status} type='success' />
                    ) : errorRes ? (
                      <Alert message={errorRes} type='error' />
                    ) : null}
                  </Col>
                  <Col></Col>
                </Row>
                <Form
                  form={form}
                  layout='vertical'
                  name='dynamic_rule'
                  onFinish={submitHandler}
                >
                  <Row className='text-white'>
                    <Col lg={6}>
                      <Form.Item
                        name='referralFirstName'
                        rules={[
                          {
                            required: true,
                            message: 'Referral First Name is required',
                          },
                          {
                            validator: checkForEmptySpaces,
                          },
                        ]}
                      >
                        <Input
                          disabled={disable}
                          placeholder='Referral First Name'
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className='px-lg-0'>
                      <Form.Item
                        name='referralLastName'
                        rules={[
                          {
                            required: true,
                            message: 'Referral Last Name is required',
                          },
                          {
                            validator: checkForEmptySpaces,
                          },
                        ]}
                      >
                        <Input
                          disabled={disable}
                          placeholder='Referral Last Name'
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name='referralEmail'
                        validateFirst={true}
                        rules={[
                          {
                            required: isSPD ? false : true,
                            message: 'Referral Email is required',
                          },
                          {
                            type: 'email',
                            message: 'Please enter a valid email address',
                          },
                          {
                            validator: checkForEmptySpaces,
                          },
                          {
                            validator: (_, value) => {
                              return new Promise((resolve, reject) => {
                                debouncedCheckingEmail()
                                resolve()
                              })
                            },
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (EmailConfirmed) {
                                return Promise.reject(
                                  'Referral email already exists'
                                )
                              } else {
                                return Promise.resolve()
                              }
                            },
                          }),
                        ]}
                      >
                        <Input disabled={disable} placeholder='Email' />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className='px-lg-0'>
                      <Form.Item
                        name='referralPhone'
                        rules={[
                          {
                            required: true,
                            message: 'Referral Phone is required',
                          },
                          {
                            validator: checkForEmptySpaces,
                          },
                          {
                            pattern: patterns.phoneNumberCheckPattern,
                            message: 'Phone number must only contain numbers.',
                          },
                          {
                            min: 10,
                            message: 'Phone number must be 10 digits.',
                          },
                          {
                            max: 10,
                            message: 'Phone number must be 10 digits.',
                          },
                        ]}
                      >
                        <Input disabled={disable} placeholder='Phone' />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item name='referralAddress1'>
                        {/* <Input disabled={disable} placeholder="Address 1" /> */}
                        <AutoComplete
                          onSearch={(term) => setSearchTerm(term)}
                          options={options}
                          virtual={false}
                          allowClear
                          onClear={() => setoptions([])}
                          onSelect={onSelect}
                          disabled={disable}
                          placeholder='Address 1'
                          autoComplete='new-password'
                          notFoundContent={
                            loading || debouncing ? (
                              <p>Loading...</p>
                            ) : (
                              'No Suggestions'
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} className='px-lg-0'>
                      <Form.Item name='referralAddress2'>
                        <Input disabled={disable} placeholder='Address 2' />
                      </Form.Item>
                    </Col>
                    <Col lg={6}>
                      <Form.Item
                        name='referralCity'
                        rules={[
                          {
                            required: true,
                            message: 'Referral City is required',
                          },
                          {
                            validator: checkForEmptySpaces,
                          },
                          {
                            pattern: patterns.onlyChar,
                            message:
                              'Special characters and numbers are not allowed',
                          },
                        ]}
                      >
                        <Input disabled={disable} placeholder='City' />
                      </Form.Item>
                    </Col>
                    <Col lg={3} className='px-lg-0'>
                      <Form.Item
                        name='referralState'
                        rules={[
                          {
                            required: true,
                            message: 'Referral State is required',
                          },
                          {
                            validator: checkForEmptySpaces,
                          },
                        ]}
                      >
                        <Select
                          virtual={false}
                          disabled={disable}
                          placeholder='State'
                        >
                          {cities.map((city, index) => {
                            return (
                              <Option key={index} value={city.value}>
                                {city.value}
                              </Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={3} className='ps-lg-1 pe-lg-0'>
                      <Form.Item
                        name='referralZip'
                        rules={[
                          {
                            required: true,
                          },
                          {
                            pattern: patterns.zipCheck,
                            message: 'Value must only contain numbers.',
                          },
                          {
                            min: 5,
                            message: 'Referral zip must be 5 digits.',
                          },
                          {
                            max: 5,
                            message: 'Referral zip must be 5 digits.',
                          },
                        ]}
                      >
                        <Input disabled={disable} placeholder='Zip' />
                      </Form.Item>
                    </Col>
                    {!hiddenRefSection && (
                      <>
                        <Col xs={12}>
                          <h6 className='text-center'>Your Information</h6>
                        </Col>
                        <Col lg={6}>
                          <Form.Item
                            name='fName'
                            rules={[
                              {
                                required: true,
                                message: 'First Name is required',
                              },
                              {
                                validator: checkForEmptySpaces,
                              },
                            ]}
                          >
                            <Input
                              disabled={disable}
                              placeholder='First Name'
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={6} className='px-lg-0'>
                          <Form.Item
                            name='lName'
                            rules={[
                              {
                                required: true,
                                message: 'Last Name is required',
                              },
                              {
                                validator: checkForEmptySpaces,
                              },
                            ]}
                          >
                            <Input disabled={disable} placeholder='Last Name' />
                          </Form.Item>
                        </Col>
                        <Col lg={6}>
                          <Form.Item
                            name='email'
                            rules={[
                              {
                                required: true,
                                message: 'Email is required',
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email address',
                              },
                              {
                                validator: checkForEmptySpaces,
                              },
                            ]}
                          >
                            <Input disabled={disable} placeholder='Email' />
                          </Form.Item>
                        </Col>
                        <Col lg={6} className='px-lg-0'>
                          <Form.Item
                            name='referrerPhone'
                            rules={[
                              {
                                validator: checkForEmptySpaces,
                              },
                              {
                                required: true,
                                message: 'Referrer Phone is required',
                              },
                              {
                                pattern: patterns.phoneNumberCheckPattern,
                                message:
                                  'Phone number must only contain numbers.',
                              },
                              {
                                min: 10,
                                message: 'Phone number must be 10 digits.',
                              },
                              {
                                max: 10,
                                message: 'Phone number must be 10 digits.',
                              },
                            ]}
                          >
                            <Input
                              disabled={disable}
                              placeholder='Referrer Phone'
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={6}>
                          <Form.Item
                            name='additionalComments'
                            label={
                              <span
                                style={{
                                  fontSize: '0.9em',
                                  color: 'gray',
                                  marginLeft: '5px',
                                }}
                              >
                                <b>Optional</b>
                              </span>
                            }
                          >
                            <Input
                              disabled={disable}
                              placeholder={
                                isSPD
                                  ? 'Additional Comments'
                                  : 'Who was your dealer? Any additional comments?'
                              }
                            />
                          </Form.Item>
                        </Col>
                        {isSPD && (
                          <Col lg={6} className='px-lg-0'>
                            <Form.Item name='energyConsultantName'>
                              <Input
                                disabled={disable}
                                placeholder='Who was your Energy Consultant?'
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col lg={12} className='text-center'>
                          <h6>Your Address</h6>
                        </Col>
                        <Col lg={6}>
                          <Form.Item
                            name='refererAddress1'
                            rules={[
                              {
                                required: true,
                                message: 'Your Address is required',
                              },
                              {
                                validator: checkForEmptySpaces,
                              },
                            ]}
                          >
                            <AutoComplete
                              onSearch={(term) => setSearchTerm(term)}
                              options={options}
                              allowClear
                              onClear={() => setoptions([])}
                              onSelect={onRefrerSelect}
                              disabled={disable}
                              placeholder='Your Address Line 1'
                              notFoundContent={
                                loading || debouncing ? (
                                  <p>Loading...</p>
                                ) : (
                                  'No Suggestions'
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={6} className='px-lg-0'>
                          <Form.Item name='refererAddress2'>
                            <Input
                              disabled={disable}
                              placeholder='Your Address 2'
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={6}>
                          <Form.Item
                            name='refererCity'
                            rules={[
                              {
                                required: true,
                                message: 'Your City is required',
                              },
                              {
                                pattern: patterns.onlyChar,
                                message:
                                  'Special characters and numbers are not allowed',
                              },
                              {
                                validator: checkForEmptySpaces,
                              },
                            ]}
                          >
                            <Input disabled={disable} placeholder='City' />
                          </Form.Item>
                        </Col>
                        <Col lg={3} className='px-lg-0'>
                          <Form.Item
                            name='refererState'
                            rules={[
                              {
                                required: true,
                                message: 'Your State is required',
                              },
                              {
                                validator: checkForEmptySpaces,
                              },
                            ]}
                          >
                            <Select
                              virtual={false}
                              disabled={disable}
                              placeholder='State'
                            >
                              {cities.map((city, index) => {
                                return (
                                  <Option key={index} value={city.value}>
                                    {city.value}
                                  </Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={3} className='ps-lg-1 pe-lg-0'>
                          <Form.Item
                            name='refererZip'
                            rules={[
                              {
                                required: true,
                                message: 'Your Zip is required',
                              },
                              {
                                validator: checkForEmptySpaces,
                              },
                              {
                                pattern: patterns.zipCheck,
                                message:
                                  'Referrer zip must only contain numbers.',
                              },
                              {
                                min: 5,
                                message: 'Referrer zip must be 5 digits.',
                              },
                              {
                                max: 5,
                                message: 'Referrer zip must be 5 digits.',
                              },
                            ]}
                          >
                            <Input disabled={disable} placeholder='Zip' />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col className='small text-secondary border-top pt-4'>
                      {/* {isSPD ? <SPDParagraph /> : <ShareSunPowerParagraph />} */}
                      <DisclaimerParagraph
                        campaignDisclaimer={campaignDisclaimer}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} hidden={disable}>
                      <Form.Item>
                        <Button
                          className='OrangeBtn'
                          onClick={() => {
                            onCheck()
                          }}
                        >
                          Send Referral
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={1}>
                      <Form.Item>
                        <Button
                          hidden={hidden}
                          className='OrangeBtn'
                          onClick={() => {
                            setDisable(false)
                            setHidden(true)
                          }}
                        >
                          Back
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={1}>
                      <Button
                        hidden={hidden}
                        htmlType='submit'
                        className='OrangeBtn'
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  )
}
export default CampaignPage
